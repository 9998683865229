/* eslint eqeqeq: 0 */
import { ArrowBackIosNewOutlined, ConfirmationNumber, Place } from "@mui/icons-material";
import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { handleClose, handleOpen } from "../config/config";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { t } from "i18next";
import { toast } from "react-toastify";
import api from "../API/Fetch_data_Api";
import { useSelector, useDispatch } from "react-redux";
import Stripe from "../PaymentGateways/Stripe";
import { setPromoCode } from "../redux/Promocode";
import { useTheme } from "@emotion/react";
import Paystack from "../PaymentGateways/Paystack";
import RazorPay from "../PaymentGateways/RazorPay";
import Paypal from "../PaymentGateways/Paypal";
import { DECIMAL_POINT } from "../config/config"

//closedrawer
const BookingInfoDrawer = ({
    setForm,
    setPromo,
    setBooking,
}) => {
    const decimal_point = DECIMAL_POINT()
    const dispatch = useDispatch();
    // for fetching cart details
    const [rows, setRows] = useState([]);
    const [amount, setAmount] = useState();
    const [visitingCharges, setVisitingCharges] = useState();
    const [subTotal, setSubTotal] = useState();
    const [coupn, setCoupn] = useState([]);
    const [addressInfo, setAddressInfo] = useState();

    const [payOnService, setPayOnService] = useState();
    const isDeliverHome = localStorage.getItem("DeliveryMethod");

    // we need this in razorpay order place api
    const [orderId, setOrderId] = useState();
    const [cod, setCod] = useState(false);
    const theme = useTheme();

    const handleCOD = () => {
        setCod(!cod);
    };

    const promo = useSelector((state) => state.Promocode);
    const codes = promo.promocode;
    // open and close drawer
    function back() {
        handleClose(setBooking);
        handleOpen(setForm);
    }

    //table row styling
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    //table cell styling
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const currency_symbol = localStorage.getItem("currencySymbol");

    let promocode = useSelector(state => state.Promocode)?.promocode;


    //for fetching cart information when drawer open
    let cartX = useSelector(state => state.cart)?.cartItems;


    let total = 0;
    cartX.forEach((obj) => {
        let price = obj.discounted_price || obj.price;
        total += price * obj.qty;
    });

    useEffect(() => {




        if (isDeliverHome === "shop") {
            setAmount(parseFloat(total).toFixed(2));
        } else {
            setAmount(parseFloat(total) + parseFloat(cartX[0]?.visiting_charges));
        }
        setSubTotal(parseFloat(total));
        setRows(cartX);
        setVisitingCharges(cartX[0]?.visiting_charges);
        setPayOnService(cartX[0]?.is_saved_for_lat);

        const appliedPromo = localStorage.getItem("selectedPromo");
        if (appliedPromo) {
            const promo = JSON.parse(appliedPromo);
            setCoupn(promo);

        } else {
            setCoupn(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // to open promocode drawer
    function openPromo() {
        handleClose(setBooking);
        handleOpen(setPromo);
    }

    //delivery method and other stuff

    const selectedDate = localStorage.getItem("selectedDate");
    const selectedTime = localStorage.getItem("selectedSlot");
    const selectedAddress = localStorage.getItem("selectedAddres");

    useEffect(() => {
        if (selectedAddress) {
            setAddressInfo(JSON.parse(selectedAddress));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //to remove coupn
    // eslint-disable-next-line no-unused-vars
    function removeCopun(id) {
        const promos = codes.filter(e => (e.id !== id))
        dispatch(setPromoCode(promos));
        localStorage.removeItem('promocode');


        const isPromoAvailable = localStorage.getItem("selectedPromo");
        if (isPromoAvailable) {
            localStorage.setItem("selectedPromo", "");
            toast.success("Coupn Removed");
            setCoupn();
        } else toast.info("No Coupn Selected");
    }

    // ==========payment integrations==============
    //when user place order
    function plaecorder() {
        const date = localStorage.getItem("selectedDate");
        const time = localStorage.getItem("selectedSlot");
        const method = localStorage.getItem("Paymethod");

        let address_id = localStorage.getItem("selectedAddres");
        let address = ""
        if (isDeliverHome === "Home") {
            address = address_id !== null || address_id === "" ? JSON.parse(address_id) : [];
        }

        let order_note = localStorage.getItem("orderNote");

        api
            .plaecOrder(method, "awaiting", "", date, time, isDeliverHome === "Home" ? 0 : 1, isDeliverHome === "Home" ? address?.id : "", order_note)
            .then((response) => response)
            .then((result) => {
                toast.success(result.message);
                if (result.error === false) {
                    const order_id = result.data.order_id;
                    setOrderId(order_id);
                    if (method === "razorpay") {
                        api
                            .createRazorOrder(orderId)
                            .then((response) => response)
                            .then((result) => {

                                if (result.error_code === null) {
                                    localStorage.removeItem("cart");
                                    localStorage.removeItem("orderNote");
                                    localStorage.removeItem("CartDetails");
                                }
                            }).then(ex => {
                                window.location.reload();
                            })
                    }

                    localStorage.removeItem("cart");
                    localStorage.removeItem("orderNote");
                    localStorage.removeItem("CartDetails");
                    localStorage.removeItem("cartItems");
                    localStorage.removeItem("selectedSlot");
                    localStorage.removeItem("selectedDate");
                    localStorage.removeItem("selectedAddres");

                    api
                        .add_transactions(order_id, "success")
                        .then((response) => response)
                        .then((res) => {

                            window.location.href = "/";
                        });
                }
            })
            .catch((error) => console.log("error", error));
    }

    //we set payment integration setting into our localstorage and from that we fetch data

    const [Key, setStripekey] = useState("");

    const [StripeStatus, setStripeStatus] = useState(false);
    const [razorStatus, setRazorStatus] = useState(false);
    const [paypalStatus, setPaypalStatus] = useState(false);
    const [paystackStatus, setPaystackStatus] = useState(false);
    const [payment_type, setPaymentType] = useState(payOnService === 1 ? "pay_on_service" : "pay_now");

    const settings = useSelector((state) => state.Settings)?.settings;
    useEffect(() => {

        const StripeStatus =
            settings.payment_gateways_settings.stripe_status;

        const RazorStatus =
            settings.payment_gateways_settings.razorpayApiStatus;
        const PayStackStatuss =
            settings.payment_gateways_settings.paystack_status;

        if (StripeStatus === "enable") {
            const stripeKey =
                settings.payment_gateways_settings.stripe_publishable_key;
            const clientSecret = settings.payment_gateways_settings.stripe_secret_key;
            localStorage.setItem("stripeKey", stripeKey);
            localStorage.setItem("clientSecret", clientSecret);
            setStripekey(stripeKey);
            setStripeStatus(true);
        } else if (RazorStatus === "enable") {
            const RazorKey = settings.payment_gateways_settings.razorpay_key;
            setStripekey(RazorKey);
            setRazorStatus(true);
        } else if (PayStackStatuss === "enable") {
            const PayStackKey =
                settings.payment_gateways_settings.paystack_key;
            setStripekey(PayStackKey);
            setPaystackStatus(true);
        } else {
            setStripekey("");
            setPaypalStatus(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);





    return (
        <Box display={"block"} width={"80"}>
            <Box mt={1} mb={1} display={"flex"} alignItems={"center"}>
                <IconButton onClick={back}>
                    <ArrowBackIosNewOutlined />
                </IconButton>
                <h3>{t("booking_info")}</h3>
            </Box>
            <Divider />
            <Box mt={2} p={2}>
                <Box border={"1px solid gray"} borderRadius={2}>
                    <Box display={"flex"} p={1} gap={2} alignItems={"center"}>
                        <AccessTimeIcon className="icon" sx={{ fontWeight: "thin" }} fontSize="large" />
                        <Box >
                            <Typography variant="caption" fontSize={14}>
                                {t("booking_date")}
                            </Typography>
                            <Typography fontWeight={"bold"}>
                                {dayjs(selectedDate).format("DD/MM/YYYY")}, {selectedTime}
                            </Typography>
                        </Box>
                    </Box>
                    {isDeliverHome === "Home" ? (
                        <>
                            <Divider />
                            <Box display={"flex"} p={1} gap={2} alignItems={"center"}>
                                <Place className="icon" fontSize="large" />
                                <Box>
                                    <Typography color={"gray"} fontSize={14}>
                                        {t("your_booking_address")}
                                    </Typography>
                                    <Typography fontWeight={"bold"}>
                                        {addressInfo ? addressInfo.address : ""}
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        ""
                    )}
                </Box>

                <Box sx={{ my: 5, }}>
                    <Button
                        variant="contained"
                        fullWidth
                        className="promocode-btn"
                        mt={2}
                        border={"1px solid"}
                        sx={{
                            textTransform: "none",
                            borderRadius: "10px",
                            backgroundColor: theme.palette.background.buttonColor
                        }}
                        p={2}
                        onClick={openPromo}
                        borderRadius={"8px"}
                    >
                        {t("aplly_promo")}
                    </Button>
                </Box>

                {(codes !== null && codes.length > 0) ?

                    <Box my={5} px={2} py={2} sx={{ backgroundColor: "#343F53", borderRadius: "8px" }} >

                        <Box display={"flex"} flexDirection={"column"}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Box display={"flex"} gap={2}>
                                    <ConfirmationNumber sx={{ color: "white" }} />
                                    <Typography color={"white"} fontWeight={"400"} variant="subtitle2">
                                        Use Coupons
                                    </Typography>
                                </Box>
                                <Typography variant="body1" color={"white"}>
                                    {coupn !== undefined && coupn !== null
                                        ?
                                        <Typography display={"flex"} alignItems={"center"} justifyContent={"center"}
                                            gap={0.4}
                                            variant="subtitle2" color={"white"}>

                                            <Box>
                                                {coupn.discount}
                                            </Box>
                                            <Box>
                                                {coupn.discount_type === "percentage" ? "%" : currency_symbol}
                                            </Box>
                                        </Typography>
                                        : "Coupons"}
                                </Typography>
                            </Box>
                        </Box>


                    </Box>
                    : ""
                }

                <Box mt={2}>
                    <Typography variant="h6">{t("payment_mode")}</Typography>
                    <Box mt={2}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={payment_type}
                            onChange={e => setPaymentType(e.target.checked)}
                            name="radio-buttons-group"
                        >
                            {payOnService == "0" ?
                                ""
                                : <>
                                    <FormControlLabel
                                        value="pay_on_service"
                                        control={<Radio />}
                                        onChange={handleCOD}
                                        label="Pay on Service"
                                    />
                                    <Typography ml={4} mt={"-10px"} mb={1} color={"gray"}>
                                        {t("pay_service_home")}
                                    </Typography>

                                    <Divider />
                                </>}

                            <FormControlLabel
                                value="pay_now"
                                control={<Radio />}
                                onChange={handleCOD}
                                label="Pay Now"
                            />
                            <Typography ml={4} mt={"-10px"} mb={1} color={"gray"}>
                                {t("pay_now")}
                            </Typography>
                            <Divider />

                        </RadioGroup>
                    </Box>
                </Box>

                {/* Table that contain information about our cart  */}
                <TableContainer className="add-address-btn" component={Paper} sx={{ mt: 5 }}>
                    <Table className="booking-info-table" aria-label="customized table">
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.servic_details.title}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {row.qty}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {currency_symbol} {row.servic_details.price_with_tax}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    {t("sub_total")}
                                </StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                                <StyledTableCell align="right">{currency_symbol} {subTotal}</StyledTableCell>
                            </StyledTableRow>
                            {isDeliverHome === "Home" ? (
                                <>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            {t("visiting_charges")}
                                        </StyledTableCell>
                                        <StyledTableCell align="right"></StyledTableCell>
                                        <StyledTableCell align="right">{currency_symbol} {visitingCharges}</StyledTableCell>
                                    </StyledTableRow>
                                </>
                            ) : ""}


                            {promocode?.length > 0 ? (
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        {t("promocode")}
                                    </StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>
                                    <StyledTableCell align="right">
                                        - {promocode[0]?.discount} 
                                        {promocode[0]?.discount_type == "percentage" ? "%" : currency_symbol}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ) : (
                                ""
                            )}
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    <Typography variant="body1" fontWeight={"bold"}>
                                        {t("total_amount")}
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                                <StyledTableCell align="right">
                                    <Typography variant="body1" fontWeight={"bold"} color={theme.palette.primary.main}>
                                        {currency_symbol}
                                        {promocode?.length > 0 ?
                                            promocode[0]?.discount_type == "percentage" ?

                                                (amount - (amount * promocode[0]?.discount) / 100)
                                                :
                                                parseFloat(amount - promocode[0]?.discount).toFixed(decimal_point)
                                            : parseFloat(amount).toFixed(decimal_point)
                                        }
                                    </Typography>

                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* at a time only one single payent gateway is enable */}
                {/* payment method integrations  */}
                {cod === true ? (
                    <Box mt={2}>
                        <Button
                            variant="outlined"
                            fullWidth
                            className="place-order-btn"
                            onClick={() => {
                                localStorage.setItem("Paymethod", "cod");
                                plaecorder();
                            }}
                        >
                            {t("place_order")}
                        </Button>
                    </Box>
                ) : (
                    <Box mt={2}>
                        {/* Render StripeCheckout only when StripeKey is available */}
                        {Key && StripeStatus === true ? (
                            <Stripe amount={
                                promocode?.length > 0 ?
                                    promocode[0]?.discount_type == "percentage" ?

                                        (amount - (amount * promocode[0]?.discount) / 100)
                                        :
                                        parseFloat(amount - promocode[0]?.discount).toFixed(decimal_point)
                                    : parseFloat(amount).toFixed(decimal_point)
                            } 
                            promoCode={promocode?.length > 0 ? promocode : []}
                            
                            ></Stripe>
                        ) : null}

                        {/* if paystack is enable  */}
                        {paystackStatus ? (

                            <>
                                <Paystack amount={
                                    promocode?.length > 0 ?
                                        promocode[0]?.discount_type == "percentage" ?

                                            (amount - (amount * promocode[0]?.discount) / 100)
                                            :
                                            parseFloat(amount - promocode[0]?.discount).toFixed(decimal_point)
                                        : parseFloat(amount).toFixed(decimal_point)
                                } 
                                promoCode={promocode?.length > 0 ? promocode : []}
                                
                                />
                            </>

                        ) : (
                            ""
                        )}
                        {paypalStatus ? (

                            <>
                                <Paypal amount={promocode?.length > 0 ?
                                    promocode[0]?.discount_type == "percentage" ?

                                        (amount - (amount * promocode[0]?.discount) / 100)
                                        :
                                        parseFloat(amount - promocode[0]?.discount).toFixed(decimal_point)
                                    : parseFloat(amount).toFixed(decimal_point)
                                } 
                                promoCode={promocode?.length > 0 ? promocode : []}
                                />
                            </>
                        ) : (
                            ""
                        )}

                        {/* ifrazorpay is enable */}
                        <Box mt={2}>
                            {razorStatus ? (
                                <RazorPay
                                    amount={promocode?.length > 0 ?
                                        promocode[0]?.discount_type == "percentage" ?

                                            (amount - (amount * promocode[0]?.discount) / 100)
                                            :
                                            parseFloat(amount - promocode[0]?.discount).toFixed(decimal_point)
                                        : parseFloat(amount).toFixed(decimal_point)
                                    }

                                promoCode={promocode?.length > 0 ? promocode : []}

                                />
                            ) : (
                                ""
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default BookingInfoDrawer;
