/* eslint eqeqeq: 0 */
import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Divider,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { handleClose, handleOpen } from "../config/config";
import { toast } from "react-toastify";
import { t } from "i18next";
import api from "../API/Fetch_data_Api";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { setAddress } from "../redux/UserAddress";


const AddAddressForm = ({ setAddressMap, setAddressForm }) => {
    let addressname = localStorage.getItem("addLocationName")
    let location = localStorage.getItem("locationValue")
    const [locationName, setLocationName] = useState(addressname !== null ? addressname : location);
    let addressAdd = JSON.parse(localStorage.getItem("addressAdd"));

    const [city, setCity] = useState(addressAdd?.city);


    const dispatch = useDispatch();
      // eslint-disable-next-line no-unused-vars
    const userAddress = useSelector((state) => state.UserAddress).address;

    const [mobile, setMobile] = useState("");
    //button toggle group
    const [selectedButton, setSelectedButton] = useState("");
    const [isDefault, setIsDefault] = useState(0);

    const handleCheckboxChange = (event) => {
        setIsDefault(1);
    };

    const handleButtonClick = (label) => {
        setSelectedButton(label);
    };
    const buttonStyles = (label) => ({
        backgroundColor: selectedButton === label ? "#0277FA" : "transparent",
        color: selectedButton === label ? "white" : "black",
    });

    const AddAddress = () => {
        const appartment = document.getElementById("apartment").value;
        const city = document.getElementById("city").value;
        const lat = localStorage.getItem("Lat");
        const lng = localStorage.getItem("Lng");

        api
            .AddAddress(
                "",
                mobile,
                locationName,
                0,
                city,
                lat,
                lng,
                appartment,
                selectedButton,
                "",
                "",
                "",
                "",
                isDefault,
                ""
            )
            .then((result) => {
                console.log(result);
                toast.success(result.message);
                if (result.error === true) {
                    for (var key of Object.keys(result.message)) {

                        if (key == "address")
                            toast.error("Address is required");
                        if (key == "city_name")
                            toast.error("City Name is required");
                        if (key == "area")
                            toast.error("Area/Appartment Name is required");
                        if (key == "mobile")
                            toast.error("Mobile Number is required");
                    }
                }

                setMobile("");
                setCity("");
                setLocationName("");

                api.getAddress().then(res => {
                    dispatch(setAddress(res.data));
                }).then(x => {
                    back();
                });

            })
            .catch((error) => console.log("error", error));
    };

    function back() {
        handleClose(setAddressForm);
        handleOpen(setAddressMap);
    }
    const handleMobileChang = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        // Limit the input to a maximum of 16 characters
        if (numericValue.length <= 16 || numericValue === '') {
            setMobile(numericValue);
        }
    };
    const theme = useTheme();
    return (
        <Box>
            <Box mt={1} mb={1} display={"flex"} alignItems={"center"}>
                <IconButton onClick={back}>
                    <ArrowBackIosNewOutlined />
                </IconButton>
                <h3>{t("complete_address")}</h3>
            </Box>
            <Divider />
            <Box p={2} display={"block"}>
                <Typography>{t("house_flat")}</Typography>
                <TextField
                    placeholder="Enter House no"
                    fullWidth
                    className="address-form"
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                    sx={{
                        my: 1,
                        backgroundColor: theme.palette.background.input,
                    }}
                />
                <Typography>{t("appartment_road")}</Typography>
                <TextField
                    id="apartment"
                    placeholder="Enter Road Name"
                    fullWidth
                    className="address-form"
                    sx={{
                        my: 1,
                        backgroundColor: theme.palette.background.input,
                    }}
                />
                <Typography>{t("city")}</Typography>
                <TextField
                    id="city"
                    placeholder="Enter City Name"
                    value={city}
                    onChange={e => setCity(e.target.value)}
                    fullWidth
                    sx={{
                        my: 1,
                        backgroundColor: theme.palette.background.input,
                    }}
                    className="address-form"
                />
                <Typography>{t("mobile")}</Typography>
                <TextField
                    id="mobile"
                    placeholder="Enter Mobile Number"
                    fullWidth
                    className="address-form"
                    value={mobile}
                    sx={{
                        my: 1,
                        backgroundColor: theme.palette.background.input,
                    }}
                    onChange={handleMobileChang}
                    inputProps={{
                        pattern: "[0-9]*", // Allow only numeric input
                        inputMode: "numeric", // Display numeric keyboard on mobile devices
                    }}
                />

                <Box display={"flex"} gap={2}>
                    <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                        sx={{ marginTop: 10 }}
                    >
                        <Button
                            onClick={() => handleButtonClick("Home")}
                            style={buttonStyles("Home")}
                            sx={{ borderRadius: "10px", borderColor: "gray" }}
                        >
                            {t("home")}
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                        sx={{ marginTop: 10 }}
                    >
                        <Button
                            onClick={() => handleButtonClick("Office")}
                            style={buttonStyles("Office")}
                            sx={{ borderRadius: "10px", borderColor: "gray" }}
                        >
                            {t("office")}
                        </Button>

                    </ButtonGroup>
                    <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                        sx={{ marginTop: 10 }}
                    >
                        <Button
                            onClick={() => handleButtonClick("Other")}
                            style={buttonStyles("Other")}
                            sx={{ borderRadius: "10px", borderColor: "gray" }}
                        >
                            {t("other")}
                        </Button>
                    </ButtonGroup>
                </Box>

                <Box alignItems={"center"} display={"flex"}>
                    <Checkbox checked={isDefault} onChange={handleCheckboxChange} />
                    <Typography> {t("default_address")}</Typography>
                </Box>

                <Button
                    variant="contained"
                    fullWidth
                    className="add-address-btn"
                    onClick={() => AddAddress()}
                >
                    {t("continue")}
                </Button>
            </Box>
        </Box>
    );
};

export default AddAddressForm;
