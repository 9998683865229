// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    lat: parseFloat(localStorage.getItem("Lat")) || null,
    lng: parseFloat(localStorage.getItem("Lng")) || null,
  },
  reducers: {
    setLatitude: (state, action) => {
      state.lat = action.payload;
      localStorage.setItem("Lat", action.payload);
    },
    setLongitude: (state, action) => {
      state.lng = action.payload;
      localStorage.setItem("Lng", action.payload);
    },
  },
});

export const { setLatitude, setLongitude } = locationSlice.actions;

export default locationSlice.reducer;
