import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
    typography: {
        fontFamily: [
            '"Plus Jakarta Sans"',
        ].join(','),
    },
    palette: {
        mode: "light",
        primary: {
            main: "#0277FA",
        },
        secondary: {
            main: "#f48fb1",
        },
        background: {
            paper: "#f2f1f6",
            box: "#ffffff",
            card: "white",
            addressBox: "#Fdfdfd",
            input: "white",
            provider: "white",
            booking: "#f9f8f8",
            heading: "#e8e8e8f2",
            categories: "white",
            navLink: "black",
            buttonColor: "#343F53"
        },
        color: {
            navLink: "black",
            logo: "var(--global-theme)",
            catLink: "black",
            secondary: "#575757",
            phone: "#2664f7",
            text: "white",
            breadcrum: "#5d5d5d",
            subCatName: "white",
            textColor: "black",
            danger: "#CF2D2D",
            categories: "black",
        },
        fonts: {
            h1: "24pt",
            h2: "20pt",
            h3: "16pt",
            h4: "12pt",
        },
        icons: {
            icon: "black",
        },
    },
});

const darkTheme = createTheme({
    typography: {
        fontFamily: [
            '"Plus Jakarta Sans"',
        ],
    },
    palette: {
        mode: "dark",
        primary: {
            main: "#0277FA",
        },
        secondary: {
            main: "#343f53",
        },
        background: {
            paper: "#041C32", // Set the color of the paper background
            box: "#041C32",
            addressBox: "#343F53",
            card: "#343f53",
            input: "#3d3f48",
            provider: "#1b3145",
            booking: "#041C32",
            heading: "#041C32",
            categories: "#11283d",
            navLink: "white",
            buttonColor: "#0277FA"
        },
        color: {
            navLink: "white",
            logo: "white",
            catLink: "white",
            secondary: "white",
            phone: "white",
            text: "black",
            breadcrum: "#5d5d5d",
            subCatName: "white",
            textColor: "white",
            danger: "#CF2D2D",
            categories: "black",
        },
        fonts: {
            h1: "24pt",
            h2: "20pt",
            h3: "16pt",
            h4: "12pt",
        },
        icons: {
            icon: "white",
        },
    },
});

export { darkTheme, lightTheme };
