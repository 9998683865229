import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import {
    Box,
    Button,
    Divider,
    IconButton,
} from "@mui/material";
import React from "react";
import { handleClose, handleOpen } from "../config/config";
import { useState } from "react";
import { useEffect } from "react";
import { t } from "i18next";
import { extractAddress } from "../util/Helper";

const UpdateMapSide = ({ closeMap, openForm }) => {
    function back() {
        handleClose(closeMap);
    }
    function CompleteAddress() {
        handleClose(closeMap);
        handleOpen(openForm);
    }


    const [condition, setCondition] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);
    const EditableAddress = localStorage.getItem("EditableAddress");
    const Addresses = JSON.parse(EditableAddress);
    const lat = (parseFloat(Addresses?.lattitude));
    const lng = (parseFloat(Addresses?.longitude));

    useEffect(() => {

        initMap();
        // Update the mapLoaded flag once the map is loaded
        setMapLoaded(true);

        setCondition(!condition);

        if (mapLoaded) {
            // Store the mapLoaded status in localStorage
            localStorage.setItem("mapLoaded", JSON.stringify(mapLoaded));
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const initMap = () => {
        if (isNaN(lat) || isNaN(lng)) {
            console.error("Invalid latitude or longitude values");
            return;
        }

        const geocoder = new window.google.maps.Geocoder(); // Define the geocoder here


        const map = new window.google.maps.Map(document.getElementById("map"), {
            center: { lat: parseFloat(lat), lng: parseFloat(lng) },
            zoom: 8,
            streetViewControl: false,
        });

        // Add a marker to the map
        const marker = new window.google.maps.Marker({
            map: map,
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            draggable: true,
        });


        map.addListener("click", (event) => {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();

            localStorage.setItem("addLat", lat);
            localStorage.setItem("addLong", lng);

            const location = new window.google.maps.LatLng(lat, lng);
            geocoder.geocode({ location: location }, (results, status) => {
                if (status === "OK") {
                    if (results[0]) {
                        const fullAddress = extractAddress(results[0]);

                        const address = results[0].formatted_address;
                        localStorage.setItem("addLocationName", address);
                        localStorage.setItem("edit-city", fullAddress.city);

                        // Update marker position
                        marker.setPosition({ lat: lat, lng: lng });
                        map.panTo({ lat: lat, lng: lng });
                    } else {
                        console.error("No results found");
                    }
                } else {
                    console.error("Geocoder failed due to: " + status);
                }
            });

            // Update marker position
            marker.setPosition({ lat: lat, lng: lng });
            map.panTo({ lat: lat, lng: lng });
        });


        // map.addListener("drag", (event) => {
        //     console.log(event);
        //     const lat = event.latLng.lat();
        //     const lng = event.latLng.lng();

        //     localStorage.setItem("addLat", lat);
        //     localStorage.setItem("addLong", lng);

        //     const location = new window.google.maps.LatLng(lat, lng);
        //     geocoder.geocode({ location: location }, (results, status) => {
        //         if (status === "OK") {
        //             if (results[0]) {
        //                 const address = results[0].formatted_address;
        //                 localStorage.setItem("addLocationName", address);

        //                 // Update marker position
        //                 marker.setPosition({ lat: lat, lng: lng });
        //                 map.panTo({ lat: lat, lng: lng });
        //             } else {
        //                 console.error("No results found");
        //             }
        //         } else {
        //             console.error("Geocoder failed due to: " + status);
        //         }
        //     });

        //     // Update marker position
        //     marker.setPosition({ lat: lat, lng: lng });
        //     map.panTo({ lat: lat, lng: lng });
        // });
    };

    return (
        <Box>
            <Box mt={1} mb={1} display={"flex"} alignItems={"center"}>
                <IconButton onClick={back}>
                    <ArrowBackIosNewOutlined />
                </IconButton>
                <h3>{t("update_location_map")}</h3>
            </Box>
            <Divider />
            <Box p={2}>
                <div id="map" className="h-430"></div>
            </Box>

            <Box p={2}>
                <Button variant="contained" fullWidth onClick={CompleteAddress}>
                    {t("complete_address_button")}
                </Button>
            </Box>
        </Box>
    );
};

export default UpdateMapSide;
