/* eslint eqeqeq: 0 */
import React from "react";
import { useTheme } from "@emotion/react";
import { ArrowRightAltOutlined, Done } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Checkbox,
    Divider,
    Rating,
    Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import slugify from "slugify";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { toast } from "react-toastify";
import { t } from "i18next";
import api from "../../../API/Fetch_data_Api";
import { useSelector, useDispatch } from "react-redux";
import { setBookmark } from "../../../redux/Bookmark";

const Partner = ({ partner, isHome = false }) => {
    const { company_name, ratings, average_rating, number_of_orders, banner_image, image, id } = partner;
    const theme = useTheme();
    const slug = slugify(company_name, { lower: true });
    const partner_id = partner.partner_id !== undefined ? partner.partner_id : id;

    const login = localStorage.getItem("isLoggedIn");

    const dispatch = useDispatch();
    const marked = useSelector((state) => state.Bookmark);

    const updateBookmark = (item) => {
        const isBookmarked1 = marked.bookmark.some((bookmark) => bookmark.partner_id === item.partner_id);

        if (isBookmarked1) {
            dispatch(setBookmark(marked.bookmark.filter((bookmark) => bookmark.partner_id !== item.partner_id)));
        } else {
            const newMark = { "partner_id": item.partner_id };
            const updatedMarkedArray = [...marked.bookmark, newMark];
            dispatch(setBookmark(updatedMarkedArray));
        }

        const lat = localStorage.getItem("Lat");
        const lng = localStorage.getItem("Lng");

        const formData = new FormData();
        formData.append("type", isBookmarked1 ? "remove" : "add");
        formData.append("partner_id", item.partner_id !== undefined ? item.partner_id : item.id);
        formData.append("latitude", lat);
        formData.append("longitude", lng);

        if (login === "true") {
            api.get_bookmarks(formData).then((response) => {
                toast.success(response.message);
            });
        }
    };

    const handle = (item) => {
        const login = localStorage.getItem("isLoggedIn");

        if (!login) {
            toast.error("Please Login !");
            return;
        }

        updateBookmark(item);
    };

    const isBookmarked = marked.bookmark.some((bookmark) => bookmark.partner_id === partner.partner_id);

    const styles = {
        mt: "10px",
        alignContent: "end",
        position: "absolute",
        background: "#FFFFFF",
        borderRadius: "12px",
        '@media (min-width: 320px)': { marginLeft: '215px' },
        '@media (min-width: 375px)': { marginLeft: '295px' },
        '@media (min-width: 425px)': { marginLeft: '330px' },
        '@media (min-width: 620px)': { marginLeft: '330px' },
        '@media (min-width: 1024px)': { marginLeft: '240px' },
        '@media (min-width: 1440px)': { marginLeft: '270px' },
    };

    return (
        <Card
            key={id}
            mb={{ xs: "10px", md: 5 }}
            sx={{
                maxWidth: 380,
                display: "inline-block",
                width: "100%",
                my: { xs: "10px", md: 0 },
                mr: 2,
                borderRadius: "var(--global-border-radius)",
            }}
        >


            <Link
                key={id}
                to={"/providers/services/" + partner_id + "/" + slug}
                className="breadcrumb text-light"
            >
                <CardMedia
                    height={240}
                    width={"max-content"}
                    component={"img"}
                    sx={{
                        borderRadius: "var(--global-border-radius)",
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0
                    }}
                    image={banner_image}
                />

                <Box
                    className="partner-cardmedia"
                    p={"5px"}
                    sx={{ bgcolor: "white" }}
                >
                    <CardMedia
                        image={image}
                        alt={company_name}
                        sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%"
                        }}
                    />
                </Box>

                <Box textAlign={"center"} >
                    <CardContent sx={{ pb: "0!important", px: "0!important" }}>
                        {!isHome ?
                            <Box
                                display={"flex"}
                                sx={{
                                    position: "relative",
                                    top: "-300px",
                                    right: { xs: "-240px", sm: "-320px", md: "-295px" },
                                    background: "#FFFFFF",
                                    borderRadius: "12px",
                                    width: { xs: "12%", sm: "10%", md: "11%" }
                                }}
                            >
                                <Checkbox
                                    size="small"
                                    inputProps={{ "aria-label": "Checkbox demo" }}
                                    id={id}
                                    checked={isBookmarked}
                                    sx={{ color: 'white' }}
                                    icon={<BookmarkBorderIcon sx={{ color: theme.palette.primary.main }} />}
                                    checkedIcon={<BookmarkIcon sx={{ color: theme.palette.primary.main }} />}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handle(partner);
                                    }}
                                />
                            </Box> : ""
                        }

                        <Typography
                            variant="h5"
                            component="div"
                            textAlign={"center"}
                            color={theme.palette.color.navLink}
                        >
                            {company_name}
                        </Typography>

                        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={1}>
                            <Box display={"flex"} alignItems={"center"} gap={1}>
                                <Rating
                                    name="read-only"
                                    value={parseInt(ratings ?? average_rating)}
                                    readOnly
                                    className="aling-content"
                                />
                                <Typography variant="body1" color={theme.palette.color.textColor}>
                                    ({ratings !== undefined ? parseFloat(ratings).toFixed(1) : parseFloat(average_rating).toFixed(2)} / 5  )
                                </Typography>
                            </Box>

                            <Button size="small" sx={{ borderRadius: "8px" }} variant="contained" startIcon={<Done sx={{ backgroundColor: "white", borderRadius: "50%", color: "#0277FA" }} />}>
                                <Box display={"flex"} gap={.5}>
                                    <Box>
                                        {number_of_orders}
                                    </Box>
                                    <Box>
                                        {t("order_completed")}
                                    </Box>
                                </Box>
                            </Button>
                        </Box>

                        <Divider sx={{ mt: 2 }} />

                        <CardActionArea sx={{
                            transition: "ease-in",
                            "&:hover": {
                                backgroundColor: "#0277FA",
                                color: "white",
                                '& .MuiBox-root': {
                                    color: "white",
                                },
                            },
                        }}>
                            <Box
                                color={theme.palette.color.navLink}
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{ width: "100%", height: "100%", padding: "10px" }}
                            >
                                {t("view_all_services")} <ArrowRightAltOutlined />
                            </Box>
                        </CardActionArea>
                    </CardContent>
                </Box>
            </Link>
        </Card>
    );
};

export default Partner;
