import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import api from '../API/Fetch_data_Api';
import { toast } from 'react-toastify';
import { useTheme } from '@emotion/react';

const RazorPay = ({ amount , promoCode}) => {
    const settings = useSelector((state) => state.Settings)?.settings;
    const currency_code = localStorage.getItem('currencyCode');

    const RazorKey = settings.payment_gateways_settings.razorpay_key;

    const date = localStorage.getItem('selectedDate');
    const time = localStorage.getItem('selectedSlot');
    
    const address_id = localStorage.getItem('selectedAddres');
    const DeliveryMethod = localStorage.getItem('DeliveryMethod');
    console.log(DeliveryMethod);
    const address = address_id !== "" ? JSON.parse(address_id) : "";
    let order_note = localStorage.getItem('orderNote');

    const theme = useTheme();
    const [popupClosed, setPopupClosed] = useState(false);

    const handlePayment = () => {
        localStorage.setItem('Paymethod', 'razorpay');

        api
            .plaecOrder('razorpay', 'awaiting', '', date, time, DeliveryMethod,  DeliveryMethod === "Home" ? address.id : "", order_note, promoCode.length > 0 ? promoCode[0].promo_code : "")
            .then((result) => {
                if (result.error === false) {
                    let order_id = result.data.order_id;
                    api.createRazorOrder(result.data.order_id).then((rex) => {
                        console.log(rex);
                    });

                    const options = {
                        key: RazorKey,
                        amount: parseInt(amount) * 100,
                        currency: currency_code,
                        name: 'eDemand',
                        description: 'Payment for Your Product',
                        handler: function (response) {
                            console.log(response);

                            // Handle Razorpay popup closure here
                            if (response && response.razorpay_payment_id) {
                                setPopupClosed(true);
                                console.log('Razorpay popup closed');
                                api.add_transactions(order_id, 'success').then((res) => {
                                    localStorage.removeItem('cart');
                                    localStorage.removeItem('orderNote');
                                    localStorage.removeItem('CartDetails');
                                    localStorage.removeItem('');
                                    localStorage.removeItem("selectedAddres");
                                }).then((res) => {
                                    window.location.reload();
                                });
                            }

                            // Additional logic for success
                            // api.add_transactions(order_id, 'success').then((res) => {
                            //     localStorage.removeItem('cart');
                            //     localStorage.removeItem('orderNote');
                            //     localStorage.removeItem('CartDetails');
                            //     localStorage.removeItem('');
                            // }).then((res) => {
                            //     window.location.reload();
                            // });
                        },
                        theme: {
                            color: theme.palette.primary.main,
                        },
                        modal: {
                            ondismiss: function () {
                                console.log('here');
                                api.add_transactions(order_id, 'cancelled').then((res) => {
                                });
                            },
                        },
                    };
                    window.Razorpay.open(options);
                } else {
                    toast.error(result.message);
                }
            });
    };

    return (
        <div>
            <Button fullWidth mx={3} variant="outlined" onClick={(e) => handlePayment()}>
                {t('make_a_payment')}
            </Button>
            {popupClosed && <p>Razorpay popup closed message</p>}
        </div>
    );
};

export default RazorPay;
