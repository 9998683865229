import axios from "axios";
import { API_URL } from "../config/config";
import { store } from "../redux/store";

// variable that we require in formdata
const lat = localStorage.getItem("Lat");
const lng = localStorage.getItem("Lng");

const deliveryMode = localStorage.getItem("DeliveryMethod");

var formdata = new FormData();
formdata.append("latitude", lat);
formdata.append("longitude", lng);

const get_stored_token = () => {
  const state = store.getState();
  return state.UserData?.profile?.profile !== undefined
    ? state.UserData?.profile?.profile?.token
    : state.UserData?.profile?.token;
};

// axios interceptors
const api = axios.create({});
api.interceptors.request.use(
  async function (config) {
    const token = get_stored_token(); // Get the token directly
    config.url = `${API_URL}` + config.url;

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// get home screen data
export const get_home_screen = async () => {
  try {
    const response = await api({
      method: "POST",
      url: "get_home_screen_data",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to fetch data");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    // Handle the error here
    console.error("Error fetching data:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// get category
export const get_category = async (search = null) => {
  try {
    const formData = new FormData();
    formData.append("latitude", lat);
    formData.append("longitude", lng);

    if (search || search !== null) {
      formData.append("search", search);
    }

    const response = await axios({
      method: "POST",
      url: "get_categories",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });

    if (!response.status === 200) {
      throw new Error("Failed to fetch categories");
    }

    const result = response.data;
    return result;
  } catch (error) {
    // Handle the error here
    console.error("Error fetching categories:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// get providers
export const get_providers = async (
  id = "",
  search = "",
  categoryId = 0,
  order = "asc",
  filter = null
) => {
  try {
    const formData = new FormData();
    formData.append("latitude", lat);
    formData.append("longitude", lng);

    if (categoryId > 0) {
      formData.append("category_id", categoryId);
    }
    if (id) {
      formData.append("partner_id", id);
    }
    if (search || search !== null) {
      formData.append("search", search);
    }
    if (filter || filter !== null) {
      formData.append("filter", filter);
    }

    formData.append("order", order);

    const response = await api({
      method: "POST",
      url: "get_providers",
      data: formData,
    });

    if (!response.status === 200) {
      throw new Error("Failed to fetch providers");
    }

    const result = response.data;
    return result;
  } catch (error) {
    console.error("Error fetching providers:", error);
    return null;
  }
};

// get service
export const get_service = async (partner_id) => {
  formdata.append("partner_id", partner_id);
  try {
    const response = await api({
      method: "POST",
      url: "get_services",
      data: formdata,
    });

    if (!response.status === 200) {
      return 0;
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching services:", error);
  }
};

// get settings
export const get_settings = async () => {
  try {
    const response = await api({
      method: "POST",
      url: "get_settings",
    });

    if (!response.status === 200) {
      return 0;
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching settings:", error);
  }
};

// get bookmarks
export const get_bookmarks = async (type) => {
  formdata.append("type", type);

  try {
    const response = await api({
      method: "POST",
      url: "book_mark",
      data: formdata,
    });

    if (!response.status === 200) {
      return 0;
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching bookmarks:", error);
  }
};

// get cart
export const get_cart = async () => {
  try {
    const response = await api({
      method: "POST",
      url: "get_cart",
      data: formdata,
    });

    if (!response.status === 200) {
      return 0;
    }

    const responseData = response.data;
    responseData.data = responseData.data?.cart_data?.data;
    responseData.data = responseData.data || [];

    return responseData;
  } catch (error) {
    console.error("Error fetching cart:", error);
    throw error; // You can rethrow the error if needed
  }
};

// get cart + basic data
export const get_cart_plain = async () => {
  try {
    const response = await api({
      method: "POST",
      url: "get_cart",
      data: formdata,
    });

    if (!response.status === 200) {
      return 0;
    }

    const responseData = response.data;
    responseData.data = responseData.data?.cart_data;
    responseData.data = responseData.data || [];

    return responseData;
  } catch (error) {
    console.error("Error fetching cart:", error);
    throw error; // You can rethrow the error if needed
  }
};

// check provider availability
export const provider_check_availability = async (
  latitude = "",
  longitude = "",
  isCheckout = 0
) => {
  const formData = new FormData();
  formData.append("latitude", latitude !== "" ? latitude : lat);
  formData.append("longitude", longitude !== "" ? longitude : lng);
  formData.append("is_checkout_process", isCheckout);

  try {
    const response = await api({
      method: "POST",
      url: "provider_check_availability",
      data: formData,
    });

    if (!response.status === 200) {
      return 0;
    }

    return response.data;
  } catch (error) {
    console.error("Error checking provider availability:", error);
    throw error; // You can rethrow the error if needed
  }
};

// Add address
// TODO: need to test this function
export const add_address = async (
  id = "",
  mobile = "",
  address = "",
  city_id = 0,
  city_name = "",
  latitude = "",
  longitude = "",
  area = "",
  type = "",
  country_code = "91",
  pincode = "",
  state = "",
  country = "",
  is_default = "",
  landmark = ""
) => {
  var formdata = new FormData();
  if (id !== null) {
    formdata.append("address_id", id);
  }
  formdata.append("mobile", mobile);
  formdata.append("address", address);
  formdata.append("city_id", city_id);
  formdata.append("city_name", city_name);
  formdata.append("lattitude", latitude);
  formdata.append("longitude", longitude);
  formdata.append("area", area);
  formdata.append("type", type);
  formdata.append("country_code", country_code);
  formdata.append("pincode", pincode);
  formdata.append("state", state);
  formdata.append("country", country);
  formdata.append("is_default", is_default);
  formdata.append("landmark", landmark);
  formdata.append("alternate_mobile", mobile);

  try {
    const response = await api({
      method: "POST",
      url: "add_address",
      data: formdata,
    });

    if (!response.status === 200) {
      return 0;
    }

    return response.data;
  } catch (error) {
    console.error("Error adding address:", error);
    throw error; // You can rethrow the error if needed
  }
};

// Check available slots
export const check_available_slot = async (
  partner_id = "",
  date = "",
  time = ""
) => {
  var formdata = new FormData();
  formdata.append("partner_id", partner_id);
  formdata.append("date", date);
  formdata.append("time", time);

  try {
    const response = await api({
      method: "POST",
      url: "check_available_slot",
      data: formdata,
    });

    if (!response.status === 200) {
      return 0;
    }

    return response.data;
  } catch (error) {
    console.error("Error checking available slots:", error);
    throw error; // You can rethrow the error if needed
  }
};

// Place Order
// TODO: need to test this function
export const place_order = async (
  method = "",
  status = "",
  order_note = "",
  date = "",
  time = "",
  at_store = 1,
  addressId = 0
) => {
  const formdata = new FormData();
  formdata.append("payment_method", method);

  // If the delivery mode is home, include the address id, otherwise set it to an empty string
  deliveryMode === "Home"
    ? formdata.append("address_id", addressId)
    : formdata.append("address_id", "");

  formdata.append("status", "awaiting");
  formdata.append("order_note", order_note);
  formdata.append("date_of_service", date);
  formdata.append("starting_time", time);
  formdata.append("at_store", deliveryMode === "Home" ? 0 : 1);

  try {
    const response = await api({
      method: "POST",
      url: "place_order",
      data: formdata,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to place order");
    }
  } catch (error) {
    console.error("Error placing order:", error);
    throw error;
  }
};

// Create razor pay order
export const create_razor_order = async (order_id = "") => {
  var formdata = new FormData();
  formdata.append("order_id", order_id);

  try {
    const response = await api({
      method: "POST",
      url: "razorpay_create_order",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to create Razor order");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error creating Razor order:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// Get available slots
export const get_available_slots = async (
  partner_id = 0,
  selectedDate = Date()
) => {
  const MyForm = new FormData();
  MyForm.append("partner_id", partner_id);
  MyForm.append("date", selectedDate);

  try {
    const response = await api({
      method: "POST",
      url: "get_available_slots",
      data: MyForm,
    });

    if (!response.status === 200) {
      throw new Error("Failed to fetch available slots");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error fetching available slots:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// manage cart
export const manage_cart = async (id = 0, qty = 0) => {
  var formdata = new FormData();
  formdata.append("service_id", id);
  formdata.append("qty", qty);

  try {
    const response = await api({
      method: "POST",
      url: "manage_cart",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to manage cart");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error managing cart:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// get services of a particular partner
export const all_services = async (partner_id, company_name) => {
  formdata.append("partner_id", partner_id);
  formdata.append("company_name", company_name);
  formdata.append("limit", 500);

  try {
    const response = await api({
      method: "POST",
      url: "get_services",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to fetch all services");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error fetching all services:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// get available promo code
export const promocode = async (partner_id = 0) => {
  var formdata = new FormData();
  formdata.append("partner_id", partner_id);

  try {
    const response = await api({
      method: "POST",
      url: "get_promo_codes",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to fetch promo codes");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error fetching promo codes:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// Validate promocode
export const validate_promocode = async (
  provider_id,
  promo_code,
  overall_amount
) => {
  var formdata = new FormData();
  formdata.append("partner_id", provider_id);
  formdata.append("promo_code", promo_code);
  formdata.append("final_total", overall_amount);

  try {
    const response = await api({
      method: "POST",
      url: "validate_promo_code",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to validate promo code");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error validating promo code:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// verify users
export const verify_user = async (phone = "", country_code = +91) => {
  var verifyUser = new FormData();
  verifyUser.append("mobile", phone);
  verifyUser.append("country_code", country_code);

  try {
    const response = await api({
      method: "POST",
      url: "verify_user",
      data: verifyUser,
    });

    if (!response.status === 200) {
      throw new Error("Failed to verify user");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error verifying user:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// get user data and token
export const get_token = async (phone = "", country = +91) => {
  var formdata = new FormData();
  formdata.append("mobile", phone);
  formdata.append("country_code", country);

  try {
    const response = await api({
      method: "POST",
      url: "manage_user",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to get token");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error getting token:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// delete address
export const delete_address = async (address_id = 0) => {
  const formData = new FormData();
  formData.append("address_id", address_id);

  try {
    const response = await api({
      method: "POST",
      url: "delete_address",
      data: formData,
    });

    if (!response.status === 200) {
      throw new Error("Failed to delete address");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error deleting address:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// get address
export const get_address = async () => {
  try {
    const response = await api({
      method: "POST",
      url: "get_address",
    });

    if (!response.status === 200) {
      throw new Error("Failed to get address");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error getting address:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// Add and Remove bookmark
export const bookmark = async (
  type = "",
  latitude = "",
  longitude = "",
  partner_id = ""
) => {
  var formdata = new FormData();
  formdata.append("type", type);
  formdata.append("latitude", latitude !== "" ? latitude : lat);
  formdata.append("longitude", longitude !== "" ? longitude : lng);
  if (partner_id) {
    formdata.append("partner_id", partner_id);
  }

  try {
    const response = await api({
      method: "POST",
      url: "book_mark",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to bookmark");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error bookmarking:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// manage user
// TODO: check this function
export const manage_user = async (
  contact = "",
  CountryCode = "",
  Myname = "",
  email = ""
) => {
  var formdata = new FormData();
  formdata.append("mobile", contact);
  formdata.append("country_code", CountryCode);
  formdata.append("username", Myname);
  formdata.append("email", email);

  try {
    const response = await api({
      method: "POST",
      url: "manage_user",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to manage user");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error managing user:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// Update User
// TODO: Check this function
export const update_user = async (
  contact = "",
  name = "",
  email = "",
  profileImage = null
) => {
  var formdata = new FormData();
  formdata.append("mobile", contact);
  formdata.append("username", name);
  formdata.append("email", email);

  if (profileImage != null) {
    formdata.append("image", profileImage);
  }

  try {
    const response = await api({
      method: "POST",
      url: "update_user",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to update user");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error updating user:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// get sub category
export const get_sub_category = async (category_id = "", title = "") => {
  formdata.append("category_id", category_id);
  formdata.append("title", title);

  try {
    const response = await api({
      method: "POST",
      url: "get_sub_categories",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to get sub category");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error getting sub category:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// Delete User
// TODO: need to test this function
export const delete_user_account = async () => {
  try {
    const response = await api({
      method: "POST",
      url: "delete_user_account",
    });

    if (!response.status === 200) {
      throw new Error("Failed to delete user account");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error deleting user account:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// Log out
// TODO: need to test this function
export const logout = async () => {
  var formdata = new FormData();
  formdata.append("all_device", "true");

  try {
    const response = await api({
      method: "POST",
      url: "logout",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to log out");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error logging out:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// get orders
// TODO: need to test this function
export const get_orders = async (
  id = "",
  currentPage = 1,
  itemsPerPage = 10,
  status = "",
  order_statuses = ""
) => {
  var formdata = new FormData();
  if (order_statuses.includes(status)) {
    formdata.append("status", status);
  }
  if (id) {
    formdata.append("id", id);
  }

  try {
    const response = await api({
      method: "POST",
      url: `get_orders?offset=${
        currentPage * itemsPerPage
      }&limit=${itemsPerPage}`,
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to get orders");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error getting orders:", error);
    throw error; // Propagate the error further if needed
  }
};

// Get user notifications
export const user_notifications = async (limit = 5, offset = 0) => {
  var formdata = new FormData();
  formdata.append("limit", limit);
  formdata.append("offset", offset);

  try {
    const response = await api({
      method: "POST",
      url: "get_notifications",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to get user notifications");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error getting user notifications:", error);
    throw error; // Propagate the error further if needed
  }
};

// get user transactions
export const get_transactions = async (limit = "") => {
  var formdata = new FormData();
  formdata.append("limit", limit ?? "10");

  try {
    const response = await api({
      method: "POST",
      url: "get_transactions",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to get transactions");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error getting transactions:", error);
    throw error; // Propagate the error further if needed
  }
};

// Remove service from cart
export const remove_cart = async (item_id) => {
  var formdata = new FormData();
  formdata.append("service_id", item_id);

  try {
    const response = await api({
      method: "POST",
      url: "remove_from_cart",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to remove from cart");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error removing from cart:", error);
    throw error; // Propagate the error further if needed
  }
};

// get rating of particular partner
export const get_ratings = async (partnerID, limit = 5, offset = 0) => {
  var formdata = new FormData();
  formdata.append("partner_id", partnerID);
  formdata.append("limit", limit);
  formdata.append("offset", offset);

  try {
    const response = await api({
      method: "POST",
      url: "get_ratings",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to fetch ratings");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error fetching ratings:", error);
    throw error; // Propagate the error further if needed
  }
};

// Add transactions
export const add_transactions = async (orderID, status) => {
  var formdata = new FormData();
  formdata.append("order_id", orderID);
  formdata.append("status", status);

  try {
    const response = await api({
      method: "POST",
      url: "add_transaction",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to add transaction");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error adding transaction:", error);
    throw error; // Propagate the error further if needed
  }
};

// send message from contact us
export const send_message = async (
  name = "",
  subject = "",
  message = "",
  email = ""
) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("subject", subject);
  formdata.append("message", message);
  formdata.append("email", email);

  try {
    const response = await api({
      method: "POST",
      url: "contact_us_api",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to send message");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error sending message:", error);
    throw error; // Propagate the error further if needed
  }
};

// give rating to a service
export const apply_rating = async (id, rating, comment, images = null) => {
  var formdata = new FormData();
  formdata.append("service_id", id);
  formdata.append("rating", rating);
  formdata.append("comment", comment);

  if (images != null) {
    formdata.append("images", images);
  }

  try {
    const response = await api({
      method: "POST",
      url: "add_rating",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to apply rating");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error applying rating:", error);
    throw error; // Propagate the error further if needed
  }
};

// Change order status
export const change_order_status = async (order_id, status, date, time) => {
  var formdata = new FormData();
  formdata.append("order_id", order_id);
  formdata.append("status", status);
  formdata.append("date", date);
  formdata.append("time", time);

  try {
    const response = await api({
      method: "POST",
      url: "update_order_status",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to change order status");
    }

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Error changing order status:", error);
    throw error; // Propagate the error further if needed
  }
};

// DOwnload invoice
export const download_invoices = async (order_id) => {
  var formdata = new FormData();
  formdata.append("order_id", order_id);

  try {
    const response = await api({
      method: "POST",
      url: "invoice-download",
      data: formdata,
    });

    if (!response.status === 200) {
      throw new Error("Failed to download invoice");
    }

    const result = await response;
    return result;
  } catch (error) {
    console.error("Error downloading invoice:", error);
    throw error; // Propagate the error further if needed
  }
};
